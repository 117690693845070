/* 
-------------------------------------
            Buttons
-------------------------------------
*/

.button {
    color: var(--clr-background);
    background-color: var(--clr-primary-text);
    border-radius: .4375rem;
    padding: 10px 35px;
}

.button:hover {
    border: 3px solid var(--clr-primary-text);
    background-color: transparent;
    color: var(--clr-primary-text);
    font-weight: 600;
}

/* 
-------------------------------------
           Page Not Found
-------------------------------------
*/

.container_notFoundPage {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    height: 100vh;
    padding: 30px;
}

.content_notFoundPage,
.image_notFoundPage {
    flex: 1;
}

.content_notFoundPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.image_notFoundPage img {
    width: 100%;
    height: auto;
}

.buttons_notFoundPage {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
}

.buttons_notFoundPage a {
    color: var(--clr-background);
    background-color: var(--clr-primary-text);
    border-radius: .4375rem;
    padding: 10px 35px;
    text-decoration: none;
}

.buttons_notFoundPage a:hover {
    border: 2px solid var(--clr-primary-text);
    background-color: transparent;
    color: var(--clr-primary-text);
}

.buttons_notFoundPage a:nth-child(1) {
    background-color: var(--clr-accent);
}

@media screen and (min-width:340px) and (max-width:768px) {
    .container_notFoundPage {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: .3125rem;
        padding: 30px;
        height: 100%;
    }

    .content_notFoundPage {
        margin-top: 5px;
    }

    .text_notFoundPage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        line-height: 1.5;
    }

    .text_notFoundPage h1 {
        font-size: 36px;
        line-height: 1.2;
    }

    .text_notFoundPage h3 {
        font-size: 18px;
        line-height: 1.2;
    }

    .buttons_notFoundPage {
        flex-direction: column;
    }

    .buttons_notFoundPage a {
        width: 100%;
        text-align: center;
    }
}

@media screen and (min-width:541px) and (max-width:1200px){
.buttons_notFoundPage {
    display: flex;
    flex-direction: column;
}
.buttons_notFoundPage a {
    width: 100%;
    text-align: center;
}

}

/* 
-------------------------------------
            Lines
-------------------------------------
*/

/* 
-------------------------------------
        Media Queries Section
-------------------------------------
*/

/* Starts - Media Queries - Title Hero Component CSS  */
@media screen and (max-width:1024px) {}

@media screen and (max-width:378px) {}

@media screen and (max-width:540px) {}

@media screen and (max-width:450px) {}

@media screen and (max-width:378px) {}

/* Ends - Media Queries - Title Hero Component CSS  */